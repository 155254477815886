<template>
  <ElDialog
    append-to-body
    title="Send to ThreeFlow Assist"
    :visible="visible"
    data-test="threeflow assist modal"
    top="70px"
    width="500px"
    @close="closeDialog"
  >
    <div v-if="threeflowAssistProductSnapshot.length > 0">
      <h4 data-test="modal header">
        The following products are not eligible for an AI populated quote:
      </h4>
      <ul
        class="product-list"
        data-test="unsupported product types list"
      >
        <li
          v-for="(product, idx) in threeflowAssistProductSnapshot"
          :key="`${product}-${idx}`"
        >
          {{ product }}
        </li>
      </ul>
    </div>
    <p>
      ThreeFlow Assist will review, enter, and send your proposal within two business
      days. <b>You will be locked out of the product while ThreeFlow Assist processes
        your product.</b> Alternatively, you can enter the quote manually.
    </p>
    <ElCheckbox
      v-model="boxChecked"
      data-test="threeflow assist checkbox"
    >
      I acknowledge that while products are with ThreeFlow Assist I will<br>
      not be able to access these products or quote manually.
    </ElCheckbox>
    <div class="btn-group">
      <AppButton
        class="enter-rates-button"
        data-test="enter rates manually"
        size="text"
        text="Enter Manually"
        type="secondary"
        @click="handleEnterRatesManually"
      />
      <AppButton
        :is-disabled="!boxChecked"
        data-test="send to threeflow assist"
        size="large"
        :text="boxChecked ? 'Send to ThreeFlow Assist' : 'Confirm statement above'"
        type="primary"
        @click="sendToThreeFlowAssist"
      />
    </div>
  </ElDialog>
</template>

<script>
  import { mapActions, mapWritableState } from 'pinia';
  import { sendPlanAltsToThreeFlowAssist } from '@/services/documents.js';
  import { useProductStore } from '@/stores/product.js';
  import { formatArrayIntoListOfStrings } from '@/utils/general.js';

  /**
   * Allow user to choose to either send plan Alts to ThreeFlow Assist or manually enter rates for alternates
   *
   * @exports Modals/StartOrUpdateRenewalModal/ThreeFlowAssist
   */
  export default {
    name: 'ThreeFlowAssist',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      proposalDocumentId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        boxChecked: false,
      };
    },
    computed: {
      ...mapWritableState(useProductStore, [
        'threeflowAssistProductSnapshot',
      ]),
    },
    methods: {
      ...mapActions(useProductStore, ['setProducts']),
      closeDialog() {
        this.threeflowAssistProductSnapshot = [];
        this.$emit('update:visible', false);
      },
      handleEnterRatesManually() {
        this.$message.closeAll();
        this.closeDialog();
      },
      async sendToThreeFlowAssist() {
        this.$emit('showProductTableInfoMessage', `${formatArrayIntoListOfStrings(this.threeflowAssistProductSnapshot)} supported by ThreeFlow Assist. ThreeFlow will enter your quote within two business days and send it to you to review and submit.`);

        try {
          const { document: { products } } = await sendPlanAltsToThreeFlowAssist(this.proposalDocumentId);

          this.setProducts(products);
        } catch (error) {
          this.$message({
            message: error.response?.data?.message || 'There was an error sending your alternates to ThreeFlow Assist. Please try again.',
            type: 'error',
          });
        } finally {
          this.closeDialog();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.btn-group {
  margin-top: 40px;
}

:deep(.el-checkbox__label) {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--tf-gray-dark) !important;
}

:deep(.app-button.is-primary) {
  width: 256px;

  &.is-disabled {
    .button-text {
      cursor: not-allowed;
    }
  }
}
</style>
