/**
 * Define default values for Feature Flags as a fallback in case Launch Darkly is down or a flag cannot be found
 * Feature Flags are kebab-cased keys by default in Launch Darkly but Vue-LD converts them to camelCased keys
 * therefore the Feature Flag values below should be camelCased
 * See this issue for details https://github.com/dashhudson/vue-ld/issues/22
 *
 * @exports utils/featureFlags
 */

// rff:moveSingleProductType https://watchtower.atlassian.net/browse/BPL-1854
export const moveSingleProductType = 'moveSingleProductType';
// rff:saveUserDefaultPlanDesignValues https://watchtower.atlassian.net/browse/BPL-1855
export const saveUserDefaultPlanDesignValues = 'saveUserDefaultPlanDesignValues';
// rff:userDefaultsNotifications https://watchtower.atlassian.net/browse/BPL-1856
export const userDefaultsNotifications = 'userDefaultsNotifications';
// rff:statusFilterReorder https://watchtower.atlassian.net/browse/BPL-1862
export const statusFilterReorder = 'statusFilterReorder';
// rff:userDefaultCallouts https://watchtower.atlassian.net/browse/BPL-1863
export const userDefaultCallouts = 'userDefaultCallouts';
// rff:customTableSort https://watchtower.atlassian.net/browse/BPL-1865
export const customTableSort = 'customTableSort';
// rff:cp765TablePagination https://watchtower.atlassian.net/browse/BPL-1867
export const cp765TablePagination = 'cp765TablePagination';
// rff:rateEntryAttributes https://watchtower.atlassian.net/browse/BPL-1866
export const rateEntryAttributes = 'rateEntryAttributes';
// rff:productTypesColumn https://watchtower.atlassian.net/browse/BPL-1871
export const productTypesColumn = 'productTypesColumn';
// rff:downloadAllSupplementalDocuments https://watchtower.atlassian.net/browse/BPL-1715
export const downloadAllSupplementalDocuments = 'downloadAllSupplementalDocuments';
// rff:salesPitch https://watchtower.atlassian.net/browse/BPL-1734
export const salesPitch = 'salesPitch';
// rff:hideMarketActivityTab https://watchtower.atlassian.net/browse/BPL-1792
export const hideMarketActivityTab = 'hideMarketActivityTab';
// rff:benefitCalloutComments https://watchtower.atlassian.net/browse/BPL-2852
export const benefitCalloutComments = 'benefitCalloutComments';
// rff: benefitCalloutCommentsRead https://watchtower.atlassian.net/browse/BPL-2983
export const benefitCalloutCommentsRead = 'benefitCalloutCommentsRead';
// rff: benefitCalloutCommentsRead https://watchtower.atlassian.net/browse/BPL-3021
export const brokerQuoteSubmissionEdits = 'brokerQuoteSubmissionEdits';

// These defaults will be set instead of the defaultFlagValues if a user is in the dev or test environment
// this allows devs to manually control feature toggles while developing or testing
export const developmentFlagValues = {
  [moveSingleProductType]: true,
  [saveUserDefaultPlanDesignValues]: true,
  [userDefaultsNotifications]: true,
  [statusFilterReorder]: true,
  [userDefaultCallouts]: true,
  [customTableSort]: true,
  [cp765TablePagination]: true,
  [rateEntryAttributes]: true,
  [productTypesColumn]: true,
  [downloadAllSupplementalDocuments]: true,
  [salesPitch]: false,
  [hideMarketActivityTab]: '100,110,111,116,117,118,132', // ['100,110,111,116,117,118,132', 'none']',
  [benefitCalloutComments]: true,
  [benefitCalloutCommentsRead]: true,
  [brokerQuoteSubmissionEdits]: true,
};

/**
 * Default Launch Darkly Flags (for Production, Staging and Features or if Launch Darkly can't be reached)
 * Keys match the feature flag names and values match the actual default flag values that we want to set.
 * We will need to update this object each time a new flag is added to Launch Darkly
 * and also be sure to use safe defaults here in case we cannot communicate with Launch Darkly so that
 * we do not accidentally enable a feature in production.
 */
export const defaultFlagValues = {
  [moveSingleProductType]: false,
  [saveUserDefaultPlanDesignValues]: false,
  [userDefaultsNotifications]: false,
  [statusFilterReorder]: false,
  [userDefaultCallouts]: false,
  [customTableSort]: false,
  [cp765TablePagination]: false,
  [rateEntryAttributes]: false,
  [productTypesColumn]: true,
  [downloadAllSupplementalDocuments]: false,
  [salesPitch]: false,
  [hideMarketActivityTab]: '100,110,111,116,117,118,132',
  [benefitCalloutComments]: false,
  [benefitCalloutCommentsRead]: false,
  [brokerQuoteSubmissionEdits]: false,
};
